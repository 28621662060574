import React from 'react';
import { navigate } from 'gatsby';

import { Box, Typography, Container } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';

import { FloatLogoDarkLayout } from '@components/layout';
import { SimpleButton } from '@components/form';

const NeedVerifyPass = ({ text }) => {
  return (
    <FloatLogoDarkLayout>
      <Box
        sx={{
          width: '6rem',
          height: '6rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundImage: 'linear-gradient(to right bottom, #9ba4b2, #828b9b, #6a7284, #535b6e, #3d4458)',
          borderRadius: '50%',
          boxShadow: (theme) => theme.shadows[4],
          '& svg': {
            width: '5rem',
            height: '5rem',
          },
        }}
      >
        <EmailIcon />
      </Box>
      <Container maxWidth="sm">
        <Typography variant="h4" align="center" sx={{ mt: '1rem' }}>
          Um e-mail foi enviado para o e-mail cadastrado.
        </Typography>
        <Typography variant="h5" align="center" sx={{ mt: '1.5rem' }}>
          {text ?? 'Verifique seu e-mail para recuperar sua senha.'}
        </Typography>
        <Typography variant="body1" align="center" sx={{ '& span': { fontWeight: 'bold' }, mt: '0.8rem' }}>
          (em alguns casos o e-mail vai para a caixa <span>spam</span> ou <span>lixo eletrônico</span>)
        </Typography>
        <SimpleButton sx={{ mt: '1.5rem' }} onClick={() => navigate('/')}>
          <HomeIcon sx={{ mr: '1rem' }} /> Voltar ao início
        </SimpleButton>
      </Container>
    </FloatLogoDarkLayout>
  );
};

export default NeedVerifyPass;
